import React from "react";
import { useEffect } from "react";
import { Link } from 'react-scroll';

const Navigation = () => {
  useEffect(() => {
    // ===== responsive navbar
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");

    navbarToggler.addEventListener("click", () => {
      navbarToggler.classList.toggle("navbarTogglerActive");
      navbarCollapse.classList.toggle("hidden");
    });

    //===== close navbar-collapse when a  clicked
    document
      .querySelectorAll("#navbarCollapse ul li:not(.submenu-item) a")
      .forEach((e) =>
        e.addEventListener("click", () => {
          navbarToggler.classList.remove("navbarTogglerActive");
          navbarCollapse.classList.add("hidden");
        })
      );

    // ===== Sub-menu
    const submenuItems = document.querySelectorAll(".submenu-item");
    submenuItems.forEach((el) => {
      el.querySelector("a").addEventListener("click", () => {
        el.querySelector(".submenu").classList.toggle("hidden");
      });
    });
  return () => {
      // unmount
    }
  }, []);

  return (
    <div className="ud-header absolute top-0 left-0 z-40 flex w-full items-center bg-transparent">
      <div className="container">
        <div className="-mx-4 flex flex-wrap items-center justify-between lg:flex-no-wrap">
          <div className="max-w-full px-4" style={{width: '300px'}}>
            <a href="index.html" className="navbar-logo block w-full py-5">
              <img
                src="images/logo/logo-white.svg"
                alt="logo"
                className="header-logo w-full"
              />
            </a>
          </div>
          <div className="flex items-end justify-between px-4">
            <div>
              <button
                id="navbarToggler"
                className="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden"
              >
                <span
                  className="relative my-[6px] block h-[2px] w-[30px] bg-white"
                ></span>
                <span
                  className="relative my-[6px] block h-[2px] w-[30px] bg-white"
                ></span>
                <span
                  className="relative my-[6px] block h-[2px] w-[30px] bg-white"
                ></span>
              </button>
              <nav
                id="navbarCollapse"
                className="absolute right-4 top-full hidden w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:px-4 lg:shadow-none xl:px-6"
              >
                <ul className="block lg:flex">
                  <li>
                    <Link
                      to="tjanster" spy={true} smooth={true} duration={500}
                      className="cursor-pointer mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Tjänster
                    </Link>
                  </li>
                  <li className="group relative">
                    <Link
                      to="bearbeta" spy={true} smooth={true} duration={500} 
                      className="cursor-pointer mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Bearbeta
                    </Link>
                  </li>
                  <li className="group relative">
                    <Link
                      to="referensjobb" spy={true} smooth={true} duration={500} 
                      className="cursor-pointer mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Referensjobb
                    </Link>
                  </li>
                  <li className="group relative">
                    <Link
                      to="kontakt" spy={true} smooth={true} duration={500} 
                      className="cursor-pointer mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Kontakt
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation;