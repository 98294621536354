import React from "react";

const Contact = () => {
  return (
    <section id="kontakt">
      <div className="pt-20 lg:pt-[120px] pb-20 lg:pb-[120px] bg-[#afb2b5]" style={{background: 'url(images/sections/stockholm.png)', backgroundSize: 'cover', backgroundPosition: 'right'}}>
        <div className="text-gray-600 body-font relative">
          <div className="wow fadeInUp container mx-auto flex justify-end py-[60px]" data-wow-delay=".0s">
            <div className="bg-white flex flex-wrap lg:w-1/3 md:ml-auto md:mt-0 md:w-1/2 mt-10 p-8 relative rounded-lg shadow-2xl  m-10 w-full z-10">
              <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Kontakt</h2>
              <p className="leading-relaxed mb-5 text-gray-600">Vi har väldigt goda referenser och arbetar mycket i Stockholm med omnejd.</p>
              <div className="">
                <div className="relative mb-4">
                  <div className="leading-7 text-sm text-gray-400">Telefon</div>
                  <a href="tel:0729348003" className="text-lg text-blue-500">070 - 886 97 06</a>
                </div>
                <div className="relative mb-4">
                  <div className="leading-7 text-sm text-gray-400">Adress</div>
                  <a href="https://goo.gl/maps/BKSjCif1Aty66LVK8" className="text-lg">
                    <span>Nymärsta gränd 5</span>
                    <br />
                    <span>195 30 Märsta</span>
                  </a>
                </div>
                <div className="relative mb-4">
                  <div className="leading-7 text-sm text-gray-400">Email</div>
                  <a href="mailto:ermir@alertelab.com" className="text-lg">info@apcon.se</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact

