import React from "react";
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <div className="bg-gray-900">
      <footer className="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div className="gap-12 grid grid-cols-2 lg:gap-8 lg:grid-cols-6 lg:pt-12 mb-8 md:grid-cols-4 pt-10">
          <div className="col-span-full lg:col-span-2">
            <div className="flex items-center">
              <Link to="top" spy={true} smooth={true} duration={500}
                className="inline-flex items-center gap-2 text-xl font-bold text-gray-100 md:text-2xl">
                <img src="images/logo/logo-white.svg" alt="logo" className="header-logo w-full" />
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="font-bold uppercase tracking-widest text-gray-100 cursor-pointer hover:text-secondary">
              <Link to="tjanster" spy={true} smooth={true} duration={500}>
                Tjänster
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="font-bold uppercase tracking-widest text-gray-100 cursor-pointer hover:text-secondary">
              <Link to="bearbeta" spy={true} smooth={true} duration={500}>
                Bearbeta
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="font-bold uppercase tracking-widest text-gray-100 cursor-pointer hover:text-secondary">
              <Link to="referensjobb" spy={true} smooth={true} duration={500}>
                Referensjobb
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="font-bold uppercase tracking-widest text-gray-100 cursor-pointer hover:text-secondary">
              <Link to="kontakt" spy={true} smooth={true} duration={500}>
                Kontakt
              </Link>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-800 py-8 text-center text-sm text-gray-400">
          © 2023 - A &amp; P Construction AB. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Footer;
