import React from "react";

const Features = () => {
  return (
    <section id="tjanster" className="pt-20 pb-8 lg:pt-[120px] wow fadeInUp" data-wow-delay=".3s">
      <div className="container">
        <div className="wow fadeInUp -mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mb-12 max-w-[620px] lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                Tjänster
              </span>
              <h2
                className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]"
              >
                Allt inom bygg
              </h2>
              <p
                className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed"
              >
              Vi bedriver byggverksamhet och renovering av hus, bemanning inom bygg, fastighetsservice, fastighetsförvaltning, samt flytande tjänster och därmed förenlig verksamhet.              </p>
            </div>
          </div>
        </div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="wow fadeInUp group mb-12 bg-white" data-wow-delay=".1s">
              <div className="relative z-10 mb-8 flex w-full h-[70px] min-w-[70px] max-w-[70px] items-center justify-center rounded-2xl bg-primary" style={{minWidth: '70px'}}>
                <span
                  className="absolute top-0 left-0 z-[-1] mb-8 flex w-full h-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <img src="images/icons/renovation.png" alt="renovation" height="45" width="45" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                Byggnadssnickeriarbeten
              </h4>
              <p className="mb-8 text-body-color lg:mb-24">
                Byggnadssnickeriarbeten inkluderar träarbeten som tillverkning och installation av dörrar, fönster, trappor, golv och andra interiöra och exteriöra detaljer i byggnader.
              </p>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="wow fadeInUp group mb-12 bg-white"
              data-wow-delay=".15s"
            >
              <div className="relative z-10 mb-8 flex w-full h-[70px] min-w-[70px] max-w-[70px] items-center justify-center rounded-2xl bg-primary" style={{minWidth: '70px'}}>
                <span
                  className="absolute top-0 left-0 z-[-1] mb-8 flex w-full h-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <img src="images/icons/demolition.png" alt="demolition" height="45" width="45" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                Mark- och grundarbeten
              </h4>
              <p className="mb-8 text-body-color lg:mb-24">
                Markarbeten inkluderar utgrävning, planering och dränering för en stabil grund. Grundarbeten innefattar fundamentets konstruktion, inklusive gjutning av betongplattor eller pelare för att stödja byggnaden.
              </p>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="wow fadeInUp group mb-12 bg-white" data-wow-delay=".2s">
              <div className="relative z-10 mb-8 flex w-full h-[70px] min-w-[70px] max-w-[70px] items-center justify-center rounded-2xl bg-primary" style={{minWidth: '70px'}}>
                <span
                  className="absolute top-0 left-0 z-[-1] mb-8 flex w-full h-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <img src="images/icons/plumbing.png" alt="plumbing" height="45" width="45" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                Värme- och sanitetsarbeten
              </h4>
              <p className="mb-8 text-body-color lg:mb-24">
                Värme- och sanitetsarbeten omfattar installation och reparation av rör och utrustning för värme och vattenreglering i en byggnad, inklusive sanitetsutrustning som toaletter, handfat och duschar.
              </p>
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="wow fadeInUp group mb-12 bg-white"
              data-wow-delay=".25s"
            >
              <div className="relative z-10 mb-8 flex w-full h-[70px] min-w-[70px] max-w-[70px] items-center justify-center rounded-2xl bg-primary" style={{minWidth: '70px'}}>
                <span
                  className="absolute top-0 left-0 z-[-1] mb-8 flex w-full h-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <img src="images/icons/electric.png" alt="electric" height="45" width="45" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                El-installationer
              </h4>
              <p className="mb-8 text-body-color lg:mb-24">
                El-installationer är arbeten som utförs av elektriker för att installera, reparera eller underhålla elsystem i byggnader och anläggningar, vilket kan inkludera allt från belysning och uttag till elcentraler och säkerhetsanordningar.
              </p>
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="wow fadeInUp group mb-12 bg-white"
              data-wow-delay=".3s"
            >
              <div className="relative z-10 mb-8 flex w-full h-[70px] min-w-[70px] max-w-[70px] items-center justify-center rounded-2xl bg-primary" style={{minWidth: '70px'}}>
                <span
                  className="absolute top-0 left-0 z-[-1] mb-8 flex w-full h-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <img src="images/icons/paint.png" alt="paint" height="45" width="45" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                Måleriarbeten
              </h4>
              <p className="mb-8 text-body-color lg:mb-24">
                Måleriarbeten utförs av målare och inkluderar målning, tapetsering, spackling och slipning av ytor. Det kan utföras både inomhus och utomhus som en viktig del av renoverings- eller ombyggnadsprojekt.
              </p>    
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="wow fadeInUp group mb-12 bg-white"
              data-wow-delay=".35s"
            >
              <div className="relative z-10 mb-8 flex w-full h-[70px] min-w-[70px] max-w-[70px] items-center justify-center rounded-2xl bg-primary" style={{minWidth: '70px'}}>
                <span
                  className="absolute top-0 left-0 z-[-1] mb-8 flex w-full h-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <img src="images/icons/wood.png" alt="electric" height="45" width="45" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                Golv- och väggbeläggningsarbeten
              </h4>
              <p className="mb-8 text-body-color lg:mb-24">
                Golv- och väggbeläggningsarbeten är en viktig del av bygg- och renoveringsprojekt. Det handlar om att lägga olika typer av material på golv och väggar för att skapa en hållbar och estetiskt tilltalande yta.</p>
            </div>
          </div>

        </div>
      </div>
      </section>
  )
}

export default Features;



