import React from "react";

const Gallery = () => {
  return (
    <section id="referensjobb" className="relative z-20 overflow-hidden bg-white pb-12 lg:pb-[90px]">


      <div className="bg-white py-24">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">

          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mb-12 max-w-[620px] lg:mb-20">
                <span className="mb-2 block text-lg font-semibold text-primary">Referensjobb</span>
                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">Galleri</h2>
                <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                  I vår galleri-sektion kan du se exempel på vårt arbete. Vi är stolta över att visa upp ett brett utbud av olika typer av jobb som vi har genomfört för våra kunder.
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-4 md:gap-6 xl:gap-8 mb-10">
            
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".05s">
              <img src="images/gallery/fb/1.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Renovering</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80 wow fadeInUp" data-wow-delay=".1s">
              <img src="images/gallery/fb/11.jpg" loading="lazy" alt="Belysning" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Renovering</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".2s">
              <img src="images/gallery/fb/3.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Renovering</span>
            </div>


            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".25s">
              <img src="images/gallery/fb/18.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Badrum</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".3s">
              <img src="images/gallery/fb/23.jpg" loading="lazy" alt="Badrum" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Rivning</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".35s">
              <img src="images/gallery/fb/16.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Kök</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".4s">
              <img src="images/gallery/fb/10.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Exteriör</span>
            </div>


            
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80 wow fadeInUp" data-wow-delay=".45s">
              <img src="images/gallery/fb/5.jpg" loading="lazy" alt="Kök" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Renovering</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".5s">
              <img src="images/gallery/fb/4.jpg" loading="lazy" alt="Renovering" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">VVS</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".55s">
              <img src="images/gallery/fb/14.jpg" loading="lazy" alt="Rivning" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Golv</span>
            </div>



            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".6s">
              <img src="images/gallery/fb/19.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Renovering</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".65s">
              <img src="images/gallery/fb/8.jpg" loading="lazy" alt="VVS" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Renovering</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80 wow fadeInUp" data-wow-delay=".7s">
              <img src="images/gallery/fb/20.jpg" loading="lazy" alt="Belysning" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Golv</span>
            </div>



            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".75s">
              <img src="images/gallery/fb/12.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Renovering</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80 wow fadeInUp" data-wow-delay=".8s">
              <img src="images/gallery/fb/13.jpg" loading="lazy" alt="Belysning" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Golv</span>
            </div>
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".85s">
              <img src="images/gallery/fb/9.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Kök</span>
            </div>

          </div>

          <div class="text-center mx-auto mb-[60px] lg:mb-20 max-w-[620px]">
            <p class="text-lg sm:text-xl leading-relaxed sm:leading-relaxed text-body-color mb-4">
              Följ oss på Facebook för fler referensjobb!
            </p>

            <a href="https://www.facebook.com/apconstructionab/photos" class="bg-primary font-medium hover:bg-secondary hover:text-dark inline-block mt-3 mx-2 my-1 px-6 py-3 rounded-md text-base text-white">
              Öppna Photos
            </a>
          </div>

          {/* <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
            
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".05s">
              <img src="images/gallery/1.jpg" loading="lazy" alt="Exteriör" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Exteriör</span>
            </div>
            
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80 wow fadeInUp" data-wow-delay=".1s">
              <img src="images/gallery/5.jpg" loading="lazy" alt="Belysning" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Belysning</span>
            </div>
            
            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80 wow fadeInUp" data-wow-delay=".15s">
              <img src="images/gallery/6.jpg" loading="lazy" alt="Kök" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Kök</span>
            </div>

            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".2s">
              <img src="images/gallery/7.jpg" loading="lazy" alt="Renovering" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Renovering</span>
            </div>

            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".25s">
              <img src="images/gallery/12.jpg" loading="lazy" alt="Rivning" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Rivning</span>
            </div>

            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".3s">
              <img src="images/gallery/15.jpg" loading="lazy" alt="Badrum" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Badrum</span>
            </div>

            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 wow fadeInUp" data-wow-delay=".35s">
              <img src="images/gallery/10.jpg" loading="lazy" alt="VVS" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">VVS</span>
            </div>

            <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-3 md:h-80 wow fadeInUp" data-wow-delay=".4s">
              <img src="images/gallery/22.jpg" loading="lazy" alt="Golv" className="absolute inset-0 h-full w-full object-cover object-bottom transition duration-200 group-hover:scale-110" />
              <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
              <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Golv</span>
            </div>
          </div> */}
        </div>
      </div>

    </section>
  )
}

export default Gallery;