import React from "react";

const Hero = () => {
  return (
    <div id="home" className="relative overflow-hidden bg-primary pt-[120px] pb-[120px] md:pt-[130px] lg:pt-[160px]">
      <div className="container">
        <div className="-mx-4 flex flex-wrap lg:flex-no-wrap items-center">
          <div className="w-full px-4">
            <div
              className="hero-content wow fadeInUp mx-auto max-w-[780px] text-center"
              data-wow-delay=".2s"
            >
              <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                Vi är experter på renovering i Stockholm
              </h1>
              {/* <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                Vi är experter på renovering i Stockholm
              </h1> */}
              <p
                className="mx-auto mb-10 max-w-[620px] text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed"
              >
              Våra hantverkare har gedigen erfarenhet inom såväl lägenhets och hus renovering i Stockholm som snickeri, elarbeten, måleri.
              </p>
              <ul className="mb-10 flex flex-wrap items-center justify-center">
                <li>
                  <a
                    href="tel:0708869706"
                    className="bg-secondary hover:bg-white hover:text-primary duration-300 ease-in-out font-medium hover:shadow-lg hover:text-primary inline-flex items-center justify-center px-6 py-4 rounded-lg sm:px-10 text-center text-black text-xl transition"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style={{width: '1.25rem', height: '1.25rem', marginLeft: '-0.25rem', marginRight: '0.5rem'}}>
                    <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd"></path>
                  </svg>
                  <span>070 - 886 97 06</span>
                  </a>
                </li>
              </ul>
              {/* <div className="wow fadeInUp text-center" data-wow-delay=".3s">
                <img
                  src="images/icons/tools.png"
                  alt="tools"
                  className="mx-auto w-full max-w-[300px] opacity-50 transition duration-300 ease-in-out hover:opacity-100"
                />
              </div> */}
            </div>
          </div>

          <div className="w-full px-11">
            <div
              className="wow fadeInUp relative z-10 mx-auto max-w-[845px]"
              data-wow-delay=".25s"
            >
              <div className="mt-16">
                <img
                  src="images/hero/hero.jpg"
                  alt="hero"
                  className="mx-auto max-w-full rounded-xl"
                />
              </div>
              {/* <div className="absolute z-[-1]" style={{top: -30px; right: -30px; height: 111px; width: 127px; background-image: url(&quot;data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fillRule='evenodd'%3E%3Cg id='brick-wall' fill='%23ffffff' fillOpacity='0.66'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E&quot;)}}></div>
              <div className="absolute z-[-1]" style="bottom: -30px; height: 111px; width: 127px; left: -40px; background-image: url(&quot;data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fillRule='evenodd'%3E%3Cg id='brick-wall' fill='%23ffffff' fillOpacity='0.66'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E&quot;)"></div> */}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero;