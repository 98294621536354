import React from "react";

// Sections
import Navigation from "./sections/nav";
import Hero from "./sections/hero";
import Footer from "./sections/footer";
import Steps from "./sections/steps";
import Gallery from "./sections/gallery";
import Features from "./sections/features";
import Contact from "./sections/contact";

// Features
import { Link } from 'react-scroll';

const App = () => {
  return (
    <div className="App" id="top">
      <Navigation />

      <Hero />

      <Features />

      <Steps />

      <Gallery />

      <Contact />

      <Footer />

      <Link
        to="top" spy={true} smooth={true} duration={500}
        className="back-to-top fixed bottom-8 right-8 left-auto z-[999] hidden h-10 w-10 items-center justify-center rounded-md bg-primary text-white shadow-md transition duration-300 ease-in-out hover:bg-dark"
      >
        <span
          className="mt-[6px] h-3 w-3 rotate-45 border-t border-l border-white"
        ></span>
      </Link>
    </div>
  );
};

export default App;
