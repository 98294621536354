import React from "react";

const Steps = () => {
  return (
    <section id="bearbeta" className="relative z-20 overflow-hidden bg-[#f3f4fe] pt-20 pb-12 lg:pb-[90px]">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">

          <div className="wow fadeInUp -mx-4 flex flex-wrap" data-wow-delay=".1s">
            <div className="w-full px-4">
              <div className="mb-12 max-w-[620px] lg:mb-20">
                <span className="mb-2 block text-lg font-semibold text-primary">
                  Bearbeta
                </span>
                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                  Hur fungerar det?
                </h2>
                <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                  Oavsett om det är en mindre renovering eller ett storskaligt byggprojekt, kan det genom att följa dessa steg säkerställa att arbetet slutförs i tid, inom budget och till en hög kvalitetsstandard.
                </p>
              </div>
            </div>
          </div>

          <div className="container px-5 mx-auto flex flex-wrap">
            <div className="flex flex-wrap w-full">
              <div className="md:w-1/2 md:pr-10 md:py-6">

                <div className="wow fadeInUp flex relative pb-12" data-wow-delay=".2s">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary inline-flex items-center justify-center text-white relative z-10">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-gray-900 mb-1 tracking-wider">1. Inledande konsultation och platsbedömning</h2>
                    <p className="leading-relaxed">Möta kunden för att diskutera deras behov och bedöma platsen.</p>
                  </div>
                </div>

                <div className="wow fadeInUp flex relative pb-12" data-wow-delay=".25s">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary inline-flex items-center justify-center text-white relative z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-gray-900 mb-1 tracking-wider">2. Design, uppskattning och kontraktsförhandling</h2>
                    <p className="leading-relaxed">Skapa en designplan, ge en uppskattning och förhandla och slutföra kontraktet.</p>
                  </div>
                </div>

                <div className="wow fadeInUp flex relative pb-12" data-wow-delay=".3s">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary inline-flex items-center justify-center text-white relative z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-gray-900 mb-1 tracking-wider">3. Förberedelse inför byggfasen</h2>
                    <p className="leading-relaxed">Ställa upp utrustning, säkra tillstånd och kommunicera med intressenter.</p>
                  </div>
                </div>

                <div className="wow fadeInUp flex relative pb-12" data-wow-delay=".35s">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary inline-flex items-center justify-center text-white relative z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-gray-900 mb-1 tracking-wider">4. Byggfas, inspektion och testning</h2>
                    <p className="leading-relaxed">Utföra arbetet enligt designplanen och tidslinjen, och inspektera och testa det färdiga arbetet.</p>
                  </div>
                </div>

                <div className="wow fadeInUp flex relative" data-wow-delay=".4s">
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-primary inline-flex items-center justify-center text-white relative z-10">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                  </div>
                  <div className="flex-grow pl-4">
                    <h2 className="font-bold title-font text-gray-900 mb-1 tracking-wider">Kundgodkännande och avslutning</h2>
                    <p className="leading-relaxed"> Godkännande av det färdiga arbetet, betalning av avgifter och tillhandahållande av nödvändig dokumentation eller garantier.</p>
                  </div>
                </div>

              </div>

              <img className="wow fadeInUp md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12" data-wow-delay=".45s" src="images/sections/handshake.jpg" alt="step" />

            </div>
          </div>

        </div>
    </section>
  )
}

export default Steps;